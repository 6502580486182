<template>
    <v-container fluid>
        <v-form @submit.prevent="saveOrder">
            <v-card flat light>
                <v-card-text class="px-0">
                    <v-row>
                        <v-col cols="12">
                            <v-subheader class="headline pa-0">{{ $t('goods_for_shipment') }}</v-subheader>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="filteredHeaders" :items="products" :options.sync="options"
                              :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              :no-results-text ="$t('missing_data')"
                              :no-data-text ="$t('missing_data')"
                              hide-default-footer>
                        <template v-slot:item.total="{ item }">
                            {{ item.remaining * item.price }}
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="mr-2" @click="productDelete(item)" :title="$t('delete')">
                                        mdi-delete-forever
                                    </v-icon>
                                </template>
                                <span>{{$t('delete')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text class="px-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline pa-0">{{ $t('customer_data_to_whom_to_deliver') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>

                            <v-col cols="12" >
                                <v-row>

                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="name_client" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="name_client" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-home-floor-1"
                                                          :label="$t('client_name')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="phone" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="phone" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-home-floor-1"
                                                          :label="$t('phone')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <datetime-picker
                                            v-model="dispatch_date"
                                            validator-name="date_time_to"
                                            validator-rules="required"
                                            :disabled="loading"
                                            format="24hr"
                                            prepend-icon="mdi-calendar"
                                            :label="$t('delivery_date')"
                                            :locale="lang"
                                            :min-date-time="$moment().format('YYYY-MM-DD HH:mm:ss')"
                                            timeFormat="HH:mm"
                                            color="primary" readonly
                                            outlined
                                            tp
                                            dense
                                            clearable>
                                        </datetime-picker>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>




                    </v-card-text>


                    <v-card-text class="px-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline pa-0">{{ $t('delivery_address') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-0" sm="6" v-if="false">
                                <!--  @boundschange="onBoundsChange" -->
                                <yandex-map class="py-0 my-0" v-if="coords && showMap" ref="map"
                                            :coords="coords"
                                            :controls = "[]"
                                            :show-all-markers="showAllMarkers"
                                            :zoom="zoom"

                                            @map-was-initialized="initHandler"

                                >
                                </yandex-map>
                                <div  ref="marker" id ="marker"></div>


                                <div v-if="(coords[0] === 0 && coords[1] === 0)"
                                     class="mt-2" style="color: red">
                                    {{ $t('location_coordinates_not_set') }}
                                </div>

                            </v-col>
                            <v-col cols="12" >
                                <v-row>

                                    <v-col class="py-0" cols="12">
                                        <yandex-map class="py-0 my-0" v-if="coords && showMap" ref="map"
                                                    :coords="coords"
                                                    :controls = "[]"
                                                    :show-all-markers="showAllMarkers"
                                                    :zoom="zoom"
                                                    @map-was-initialized="initHandler"
                                                    style="background-color: #ffffff"
                                        >
                                        </yandex-map>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="country" rules="required" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="country" :items="countryItems"
                                                            :error="!valid" :error-messages="errors"
                                                            item-text="name" item-value="uuid" prepend-icon="mdi-web"
                                                            :loading="loadingCountries" :disabled="loading"
                                                            :label="$t('country')" @click:clear="clearCountries"

                                                            outlined

                                                            dense
                                                            color="primary" return-object clearable >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" >
                                        <ValidationProvider  ref="city" rules="required" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="city" :items="cityItems"
                                                            :error="!valid" :error-messages="errors"
                                                            item-text="name" item-value="uuid" prepend-icon="mdi-city"
                                                            :loading="loadingCities" :disabled="loading || !country"


                                                            :label="$t('city')" @click:clear="clearCities"
                                                            outlined

                                                            dense
                                                            color="primary" return-object clearable autocomplete="off">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="street" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="street" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-map-marker-radius"
                                                          :label="$t('street')" color="primary"
                                                          @click="openMap"
                                                          outlined

                                                          dense
                                                          readonly
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="house" rules="required|min:1|max:10"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="house" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-home-floor-1"
                                                          :label="$t('house')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="apartment" rules="min:1|max:10"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="apartment" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-home-floor-1"
                                                          :label="$t('apartment')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" class="py-0 my-0 text-center" v-if="!additional_fields"><span @click="additional_fields=true" class="py-0 my-0 blue--text cursor-pointer">{{$t('additional_fields')}}</span></v-col>

                                    <template v-if="additional_fields">
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="entrance" rules="min:1|max:10"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="entrance" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-home-flood"
                                                          :label="$t('entrance')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="floor" rules="min:1|max:10"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="floor" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-hiking"
                                                          :label="$t('floor')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="block" rules="min:1|max:10"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="block" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-hospital-building"
                                                          :label="$t('block')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="housing" rules="min:1|max:10"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="housing" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-page-layout-body"
                                                          :label="$t('housing')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="zip" rules="min:1|max:50"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="zip" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-map-marker-radius"
                                                          :label="$t('zip')" color="primary"
                                                          outlined
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    </template>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row v-if="additional_fields">
                            <v-col cols="12">
                                <ValidationProvider ref="comment" rules="min:3|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="comment" type="text"
                                                :error="!valid" :error-messages="errors"
                                                :disabled="loading" :label="$t('comment')"
                                                rows="5" color="primary" auto-grow
                                                outlined
                                                dense
                                                clearable>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-col cols="12" class="py-0 my-0 pa-0 text-center" v-if="additional_fields"><span @click="additional_fields=false" class="py-0 my-0 blue--text cursor-pointer">{{$t('hide_additional_fields')}}</span></v-col>

                    </v-card-text>

                    <v-card-actions class="px-0 py-6" v-if="$vuetify.breakpoint.xsOnly">
                        <v-btn type="submit" :disabled="invalid || loading || (coords[0] === 0 && coords[1] === 0)"
                               block
                               :loading="loading" color="primary">
                            {{ $t('send') }}
                        </v-btn>
                    </v-card-actions>

                    <v-card-actions class="px-0 py-6"  v-if="!$vuetify.breakpoint.xsOnly">
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading || (coords[0] === 0 && coords[1] === 0)"
                               :block="$vuetify.breakpoint.xsOnly"
                               :loading="loading" color="primary">
                            {{ $t('send') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'

import {mapActions, mapGetters} from "vuex"
import debounce from "lodash/debounce"
import {loadYmap, yandexMap} from "vue-yandex-maps"
import $ from "jquery";
import cache from "../plugins/localStorage";
import DatetimePicker from '../components/DatetimePicker.vue'



export default {
    name: 'OrderForm',
    components: {
        ValidationProvider,
        ValidationObserver,
        yandexMap,
        DatetimePicker,
    },
    inject: ['forceRerender'],
    data() {
        return {
            additional_fields:false,
            heading: null,
            progress: 0,
            tab: 0,
            language: null,
            street: null,
            house: null,
            entrance: null,
            floor: null,
            apartment: null,
            block: null,
            housing: null,
            zip: null,
            comment: null,
            country: null,

            countryItems: [],
            city: null,
            cityItems: [],
            point_x: window.innerWidth / 2 - 24,
            point_y: window.innerHeight / 2 - 48,

            loading: false,
            loadingCities: false,
            loadingCountries: false,
            ymaps: null,
            coordinates: [],
            showMap: false,
            showAllMarkers: false,
            mapMarker: null,
            hintMapContent: null,
            marker: null,
            zoom: 18,
            markerIcon: {
                preset: 'islands#redDotIcon',
                balloonShadow: false,
                balloonPanelMaxMapArea: 0,
                hideIconOnBalloonOpen: false,
                useMapMarginInDragging: true,
                layout: 'default#image',
                imageHref: '../img/marker.png',
                imageSize: [32, 32],
                imageOffset: [-32, -32],
                //imageOffset: [0, 0],
                //content: '123 v12',
                contentOffset: [-130, 20],
                //contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
            },
            myMap: null,
            geocoderMetaDataCash:{
                point: [],
                street: null,
                house: null,
            },


            options: {},
            products: [],
            headers: [
                {
                    text: this.$t('barcode'),
                    align: "left",
                    sortable: false,
                    value: "barcode"
                },
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name",
                },

                {
                    text: this.$t('price'),
                    align: "center",
                    sortable: false,
                    filterable: false,
                    value: "price",
                },
                {
                    text: this.$t('quantity'),
                    align: "center",
                    sortable: false,
                    filterable: false,
                    value: "remaining",
                },
                {
                    text: this.$t('total'),
                    align: "center",
                    sortable: false,
                    filterable: false,
                    value: "total",
                },

                {
                    text: this.$t('delete'),
                    align: this.mini ? "right" : "center",
                    value: 'action',
                    filterable: false,
                    sortable: false,
                    width: 150,
                },
            ],
            dispatch_date: null,
            name_client: null,
            phone: null,

        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
        coords: {
            get: function () {
                return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [0, 0]
            },
            set: function (val) {
                this.coordinates = (val && val.length === 2) ? val : [0, 0]
            }
        },
        mapSettings() {
            if (this.language === 'en') {
                this.$yandex.lang = 'en_US'
            } else if (this.language === 'kk') {
                this.$yandex.lang = 'ru_RU'
            } else {
                this.$yandex.lang = 'ru_RU'
            }
            return this.$yandex
        },
    },
    watch: {
        country: debounce(function (val) {
            this.getCities(val)
        }, 500),
    },
    async mounted() {
        this.loading = true
        this.language = this.languages[this.tab]
        await this.getCountries()
        this.checkCreate()


    },
    methods: {
        ...mapActions(['setOrderCache']),
        clearCountries() {
            this.country = null
            this.city = null
            this.cityItems = []
        },
        clearCities() {
            this.city = null
        },
        async checkCreate() {
           this.heading = this.$t('address_creation')
           this.products = cache.order
           this.loading = false
           await this.ymapInit()
        },
        async ymapInit() {
            var _this = this
            if (typeof (window.ymaps) !== "undefined") {
                if(window.ymaps){
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }

            }

            await loadYmap({...this.mapSettings, debug: false})
            if (window.ymaps) {
                window.ymaps.ready(function () {
                    _this.showMap = false
                })
            }
        },
        initHandler(e) {
            var _this = this;
            if (this.coords && (this.coords.length !== 2 || (this.coords.length === 2 && this.coords[0] === 0 && window.ymaps)) ) {

                var location = window.ymaps.geolocation.get({
                    provider: 'yandex',
                    mapStateAutoApply: false,
                    autoReverseGeocode: true,
                })
                location.then(
                    async function (result) {
                        //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                        e.geoObjects.add(result.geoObjects)
                        e.setCenter(result.geoObjects.position, 18, {duration: 300});
                        e.container.getElement().style.backgroundColor = 'white';
                    },
                    function (err) {
                        console.log('Error: ' + err)
                    }
                )
            }

            _this.myMap = e;

            _this.myMap.geoObjects.options.set('draggable', true)
            _this.myMap.geoObjects.options.set('openBalloonOnClick', false);

            _this.myMap.container.enterFullscreen();
            let mySearchControl = new window.ymaps.control.SearchControl({
                options:
                    {
                        float: 'right',
                        floatIndex: 100,
                        zoom: 18,
                        noPlacemark: true,
                        noPopup: true,
                        fitMaxWidth: true,
                        noSuggestPanel: true,
                        position: {
                            top: 10,
                            right: 55
                        }
                    }
            });
            _this.myMap.controls.add(mySearchControl);
            // Нажатие кнопки найти в
            mySearchControl.events.add('submit', function () {
                let query = mySearchControl.getRequestString();
                window.ymaps.geocode(query, {
                    // Указываем, что ответ должен быть в формате JSON.
                    json: true,
                    // Устанавливаем лимит на кол-во записей в ответе.
                    results: 1
                }).then(function (result) {
                    let point = result.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
                    let coords = point.split(" ")
                    _this.myMap.setCenter([coords[1],coords[0]],18);
                }, this);
            }, this);


            let
                CustomControlClass = function (options) {
                    CustomControlClass.superclass.constructor.call(this, options);
                    this._$content = null;
                    this._geocoderDeferred = null;
                };
            // И наследуем его от collection.Item.
            new window.ymaps.util.augment(CustomControlClass, window.ymaps.collection.Item, {
                onAddToMap: function (map) {
                    CustomControlClass.superclass.onAddToMap.call(this, map);
                    this._lastCenter = null;
                    this.getParent().getChildElement(this).then(this._onGetChildElement, this);
                },

                onRemoveFromMap: function (oldMap) {
                    this._lastCenter = null;
                    if (this._$content) {
                        this._$content.remove();
                        this._mapEventGroup.removeAll();
                    }
                    CustomControlClass.superclass.onRemoveFromMap.call(this, oldMap);
                },

                _onGetChildElement: function (parentDomContainer) {
                    // Создаем HTML-элемент с текстом.
                    this._$content =    $('<div class="customControl" style="font-weight: bold;font-size: 1.1em;position: fixed;position: fixed;text-align: center !important;width:100% ">Укажите адрес доставки</div>').appendTo(parentDomContainer);
                    this._mapEventGroup = this.getMap().events.group();
                    // Запрашиваем данные после изменения положения карты.
                    this._mapEventGroup.add('boundschange', this._createRequest, this);
                    // Сразу же запрашиваем название места.
                    this._createRequest();
                },

                _createRequest: function() {
                    var lastCenter = this._lastCenter = this.getMap().getCenter().join(',');
                    _this.geocoderMetaDataCash.point = this._lastCenter
                    // Запрашиваем информацию о месте по координатам центра карты.
                    window.ymaps.geocode(this._lastCenter, {
                        // Указываем, что ответ должен быть в формате JSON.
                        json: true,
                        // Устанавливаем лимит на кол-во записей в ответе.
                        results: 1
                    }).then(function (result) {
                        // Будем обрабатывать только ответ от последнего запроса.
                        if (lastCenter === this._lastCenter) {
                            this._onServerResponse(result);
                        }
                    }, this);
                },

                _onServerResponse: function (result) {
                    // Данные от сервера были получены и теперь их необходимо отобразить.
                    // Описание ответа в формате JSON.
                    var members = result.GeoObjectCollection.featureMember,
                        geoObjectData = (members && members.length) ? members[0].GeoObject : null;
                    if (geoObjectData) {
                        //Добавляем текст на карте сверху
                        //console.log(geoObjectData.metaDataProperty.GeocoderMetaData.text);
                        this._$content.text(geoObjectData.metaDataProperty.GeocoderMetaData.text);
                        //Доавляем данные в переменные
                        let GeocoderMetaData = geoObjectData.metaDataProperty.GeocoderMetaData
                        if (GeocoderMetaData.Address.Components) {
                            let Components = GeocoderMetaData.Address.Components
                            let street_full = []
                            let district = Components.filter(item => item.kind === 'district')
                            if (district.length > 0) {
                                street_full.push(district[0].name)
                            }
                            let street = Components.filter(item => item.kind === 'street')
                            if (street.length > 0) {
                                street_full.push(street[0].name)
                            }
                            let house = Components.filter(item => item.kind === 'house')
                            if (house.length > 0) {
                                _this.geocoderMetaDataCash.house = house[0].name
                            }
                            _this.geocoderMetaDataCash.street = street_full.join(', ')
                        }

                    }
                }
            });

            var customControl = new CustomControlClass();
            _this.myMap.controls.add(customControl, {
                float: 'none',
                position: {
                    top: 50
                }
            });


            // Создаем курсор по центру
            let ButtonLayoutCursor = window.ymaps.templateLayoutFactory.createClass([
                    '<div class="my-button">',
                    '<img class="my-button__img" src="{{ data.image }}" />',
                    '</div>'
                ].join('')),

                buttonCursor = new window.ymaps.control.Button({
                    data: {
                        image: '../../img/marker.svg',
                    },
                    options: {
                        layout: ButtonLayoutCursor,
                        maxWidth: [170, 190, 220]
                    }
                });

            _this.myMap.controls.add(buttonCursor, {

                float: 'none',
                position: {
                    top: this.point_y,
                    left: this.point_x
                }
            });


            //--- создание кнопка закрыть вверху слево
            let ButtonLayoutClose = window.ymaps.templateLayoutFactory.createClass([
                    '<div style="background-color:#ffdb4d;padding:4px 5px 3px 5px;" title="{{ data.title }}" class="my-button small">',
                    '<img  class="my-button__img__close" src="{{ data.image }}" alt="X">',
                    '</div>'
                ].join('')),

                buttonClose = new window.ymaps.control.Button({
                    data: {
                        image: '../../img/close.svg',
                        title: "Закрыть"
                    },
                    options: {
                        layout: ButtonLayoutClose,
                        size: 'small',
                    }
                });

            _this.myMap.controls.add(buttonClose, {
                float: 'none',
                position: {
                    top: 10,
                    right: 20

                }
            });

            buttonClose.events.add('click', function () {
                _this.showMapClose()

            });



            // ----------- Создание кнопки Готово снизу  -----------------------
            let ButtonLayout = window.ymaps.templateLayoutFactory.createClass([
                    '<div title="{{ data.title }}" class="my-button" style="position: fixed;width: 100%;text-align: center;">',
                    '<button class="my-button__text" style="background-color: #E44812;font-size: 1.2em;padding:0.7em 1em;border: solid 1px #E0E4E6;font-weight: bold;color:#fff;">{{ data.content }}</button>',
                    '</div>'
                ].join('')),

                buttonOK = new window.ymaps.control.Button({
                    data: {
                        content: "Готово",
                        title: "Готово"
                    },
                    options: {
                        layout: ButtonLayout,

                    }
                });

            _this.myMap.controls.add(buttonOK, {
                float: 'none',
                position: {
                    bottom: 100
                }
            });

            buttonOK.events.add('click', function (e) {
                _this.MapButtonOK()
            });





        },

        openMap(){
            this.showMap = true;
        },
        onBoundsChange(e) {
            var _this = this
            this.coords = e.get('newCenter')
            var myGeocoder = window.ymaps.geocode(this.coords)
            myGeocoder.then(
                function (res) {
                    let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
                    if (GeocoderMetaData.Address.Components) {
                        let Components = GeocoderMetaData.Address.Components
                        let street_full = []
                        let district = Components.filter(item => item.kind === 'district')
                        if (district.length > 0) {
                            street_full.push(district[0].name)
                        }
                        let street = Components.filter(item => item.kind === 'street')
                        if (street.length > 0) {
                            street_full.push(street[0].name)
                        }
                        let house = Components.filter(item => item.kind === 'house')
                        if (house.length > 0) {
                            _this.house = house[0].name
                        }
                        _this.street = street_full.join(', ')
                    }
                },
                function (err) {
                    console.log('Error Load GeocoderMetaData')
                }
            )
        },
        showMapClose(){
            this.myMap.destroy();
            this.myMap = null;
            this.showMap = false;
        },
        MapButtonOK(){
            this.coords = this.geocoderMetaDataCash.point.split(',');
            this.street = this.geocoderMetaDataCash.street;
            this.house = this.geocoderMetaDataCash.house;
            this.myMap.destroy();
            this.myMap = null;
            this.showMap = false;
        },

        async getCities(str) {
            if (str) {
                this.loadingCities = true
                let params = {};
                params.filter = 'search';
                if (this.country) {
                    if (this.country.uuid) {
                        params.country = this.country.uuid;
                    } else {
                        params.country = this.country;
                    }
                }
                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("wms/city", {
                        params: params,
                    })
                    .then(res => {

                        this.cityItems = res.body.data


                    })
                    .catch(err => {
                        this.cityItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_cities'))
                    })
                    .finally(end => {
                        this.loadingCities = false
                    })
            }
        },
        async getCountries() {
            this.loadingCountries = true
            let params = {};
            params.filter = 'search';
            if (this.lang) {
                params.language = this.lang
            }
            await this.$http
                .get("wms/country", {
                    params: params,
                })
                .then(res => {
                    this.countryItems = res.body.data
                })
                .catch(err => {
                    this.countryItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_countries'))
                })
                .finally(end => {
                    this.loadingCountries = false
                })
        },
        async saveOrder() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.dispatch_date) {
                formData.append('dispatch_date', this.dispatch_date)
            }
            if (this.street) {
                formData.append('street', this.street)
            }
            if (this.house) {
                formData.append('house', this.house)
            }
            if (this.entrance) {
                formData.append('entrance', this.entrance)
            }
            if (this.floor) {
                formData.append('floor', this.floor)
            }

            if (this.apartment) {
                formData.append('apartment', this.apartment)
            }
            if (this.block) {
                formData.append('block', this.block)
            }
            if (this.zip) {
                formData.append('zip', this.zip)
            }
            if (this.comment) {
                formData.append('comment', this.comment)
            }
            if (this.housing) {
                formData.append('housing', this.housing)
            }
            if (this.name_client) {
                formData.append('name', this.name_client)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }




            if (this.country) {
                if (this.country.uuid) {
                    formData.append('country', this.country.uuid)
                } else {
                    formData.append('country', this.country)
                }
            }
            if (this.city) {
                if (this.city.uuid) {
                    formData.append('city', this.city.uuid)
                } else {
                    formData.append('city', this.city)
                }
            }

            if (this.coords && this.coords.length > 0) {
                for (let i in this.coords) {
                    formData.append(`location[${i}]`, this.coords[i])
                }
            }

            if (this.products && this.products.length > 0) {
                for (let i in this.products) {
                    formData.append(`products[${i}][product_uuid]`, this.products[i]['product_uuid'])
                    formData.append(`products[${i}][count]`, this.products[i]['remaining'])
                }
            }


            // Add
            await this.$http
                .post('wms/order', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {

                    this.$toastr.success(this.$t('order_has_been_added'))
                        cache.order = null;
                        this.setOrderCache([]);
                        this.$router.push({
                            name: 'orders'
                        })

                })
                .catch(err => {
                    this.$toastr.error(this.$t('order_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        productDelete(item){
            let new_products = [];
            this.products.forEach((res)  => {
                if(res.product_uuid !== item.product_uuid) {
                    new_products.push(res);
                }
            })
            this.products = new_products;
            cache.order = new_products;
        }
    }
}
</script>


<style>
#marker {
    background-image: url('../../public/img/marker.svg');
    width: 33px;
    height: 36px;
    position: absolute;
    z-index: 200;
}
.my-button {
    display: block;
    background: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.my-button__text {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: transparent;
}

.my-button__img {
    padding: 0;
    width: 48px;
    height: 48px;
}

.my-button_small .my-button__text {
    display: none;
}

.my-button_medium .my-button__img {
    display: none;
}

.my-button_large .my-button__text {
    margin-left: 10px;
}
.my-button-selected {
    color: #333333;
    background-color: transparent;
    border: 0;
}
</style>
